// menu-data.ts

import { MenuType } from "@/interFace/interFace";

const menu_data: MenuType[] = [
  {
    id: 1,
    hasDropdown: false,
    active: true,
    title: "HOME",
    pluseIncon: false, // No plus icon since no dropdown
    link: "/",
  },
  {
    id: 2,
    hasDropdown: false,
    megaMenu: true,
    active: true,
    title: "SHOP ALL",
    pluseIncon: false, // Adjust based on whether you want a plus icon
    link: "/shop",
  },
  {
    id: 3,
    hasDropdown: true,
    megaMenu: true,
    active: true,
    title: "BY TYPE",
    pluseIncon: true, // Show plus icon
    link: "#",
    submenus: [
      { title: "BASICS", link: "/tag/7" },
      { title: "	OVER SIZE", link: "/tag/8" },
      { title: "SHORT SLEEVES", link: "/tag/9" },
      { title: "HOODIES", link: "/tag/11" },
      { title: "JACKETS", link: "/tag/12" },
      { title: "SHORTS", link: "/tag/13" },
      { title: "PANTS", link: "/tag/14" },
      { title: "ACCESSORIES", link: "/tag/18" },
    ],
    // Optionally, add megaMenuData if needed
    megaMenuData: [
      // Define mega menu items here if applicable
    ],
  },
  // ... other menu items
  {
    id: 5,
    hasDropdown: false,
    active: true,
    title: "BIG RAMY",
    pluseIncon: false,
    link: "/big-ramy",
    collaborate: true, // Added property
  },
  {
    id: 6,
    hasDropdown: false,
    active: true,
    title: "DRAGON",
    pluseIncon: false,
    link: "/dragon",
    collaborate: true, // Added property
  },
  {
    id: 7,
    hasDropdown: false,
    active: true,
    title: "KO SQUAD",
    pluseIncon: false,
    link: "/kogear",
    collaborate: true, // Added property
  },
  {
    id: 86,
    hasDropdown: false,
    active: true,
    title: "LIMITED EDITION",
    pluseIncon: false,
    link: "/limited-ed",
  },
  {
    id: 9,
    hasDropdown: false,
    active: true,
    title: "HOT DEALS",
    pluseIncon: false,
    link: "/hot-deals",
    className: "menu-hot-deals",
  },
  {
    id: 10,
    hasDropdown: false,
    active: true,
    title: "ABOUT",
    pluseIncon: false,
    link: "/about",
  },
];

export default menu_data;
