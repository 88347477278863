import React, { useEffect, useState, ChangeEvent } from "react";
import Image from "next/image";
import Link from "next/link";

interface Product {
  id: number;
  name: string;
  sku: string;
  price: string;
  description: string;
  image: string;
  slug: string;
  category: {
    name: string;
  };
}
// 

const SearchProducts = () => {
  const [products, setProducts] = useState<Product[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [filteredProducts, setFilteredProducts] = useState<Product[]>([]);
  const [openSearchBox, setOpenSearchBox] = useState(false);

  // Fetch products from the API
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch("https://partspluseg.com/public/api/Product");
        if (!response.ok) {
          throw new Error("Failed to fetch products");
        }
        const data = await response.json();
        setProducts(data);
      } catch (error) {
      }
    };

    fetchProducts();
  }, []);

  // Handle search input change
  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value;
    setSearchQuery(query);

    // Filter products based on the query
    if (query.trim()) {
      const filtered = products.filter((product) =>
        product.name.toLowerCase().includes(query.toLowerCase())
      );
      setFilteredProducts(filtered);
      setOpenSearchBox(true);
    } else {
      setFilteredProducts([]); // Clear search results if query is empty
      setOpenSearchBox(false);
    }
  };

  return (
    <div className="container py-5">
      {/* Search Input */}
      <div className="search-bar mb-4 position-relative">
        <input
          type="text"
          placeholder="Search products..."
          value={searchQuery}
          onChange={handleSearchChange}
          className="form-control"
          style={{ paddingRight: '40px' }}
        />
        <i className="fal fa-search search-icon" style={{ position: 'absolute', right: '10px', top: '10px' }}></i>
        
        {openSearchBox && (
          <div className="search-result-box position-absolute">
            {filteredProducts.length > 0 ? (
              filteredProducts.map((product) => (
                <div className="search-item d-flex align-items-center p-2" key={product.id}>
                  <div className="product-image">
                    <Image
                      src={`https://partspluseg.com/public/${product.product_image[0].image}`}
                      alt={product.name}
                      width={160}
                      height={160}
                    />
                  </div>
                  <div className="product-details ms-3">
                    <Link href={`/shop/product/${product.id}`} className="product-title-link">
                      <h6 className="mb-1">{product.name}</h6>
                    </Link>
                    <p className="mb-0">{product.price} EGP</p>
                  </div>
                </div>
              ))
            ) : (
              <p className="p-2 mb-0">No products found.</p>
            )}
          </div>
        )}
      </div>

      <style jsx>{`
        .search-bar {
          position: relative;
          max-width: 400px;
        }

        .search-icon {
          color: #999;
          font-size: 18px;
        }

        .search-result-box {
          top: 42px;
          left: 0;
          width: 100%;
          background: white;
          border: 1px solid #ddd;
          border-radius: 4px;
          z-index: 10;
          max-height: 300px;
          overflow-y: auto;
        }

        .search-item {
          display: flex;
          align-items: center;
          border-bottom: 1px solid #f1f1f1;
        }

        .search-item:last-child {
          border-bottom: none;
        }

        .product-image img {
          border-radius: 4px;
          width:80px;
        }

        .product-details {
          flex-grow: 1;
        }

        .product-details h6 {
          font-size: 14px;
          margin-bottom: 4px;
        }

        .product-title-link {
          text-decoration: none;
          color: #007bff;
        }

        .product-title-link:hover {
          text-decoration: underline;
        }

        .product-details p {
          margin-bottom: 0;
          font-size: 12px;
        }

        .btn-sm {
          font-size: 12px;
          padding: 4px 8px;
        }
      `}</style>
    </div>
  );
};

export default SearchProducts;
