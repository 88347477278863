// src/layout/header/HeaderTwo.tsx
'use client';

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useGlobalContext from '@/hooks/use-context';
import UserIcon from '@/svg/UserIcon';
import CartIcon from '@/svg/CartIcon';
import WishlistIcon from '@/svg/WishlistIcon';
import CompareIcon from '@/svg/CompareIcon';
import {
  useTotalProductCount,
  useTotalProductWishlistCount,
} from '@/hooks/useCartQuantity';
import Link from 'next/link';
import logo from '../../../public/assets/img/logo/logo.png';
import Image from 'next/image';
import { useRouter } from 'next/navigation';
import LanguageSwitcher from '@/components/LanguageSwitcher';
import { RootState } from '@/redux/store';
import Menu from './components/Menu';
import SearchHeaderTwo from './components/SearchHeaderTwo';
import axios from 'axios';
import { logout } from '@/redux/slices/authSlice'; // Import logout action

const HeaderTwo: React.FC = () => {
  const [open, setOpen] = useState(true);
  const [slogan, setSlogan] = useState<string>(
    'Further reductions: enjoy an extra 20% off our Sale and free home delivery'
  );
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const router = useRouter();
  const dispatch = useDispatch(); // Initialize dispatch

  // Accessing global context
  const {
    setSideCartOpen,
    sideCartOpen,
    setSideWishlistOpen,
    sideWishlistOpen,
    toggleSideMenu,
    scrollDirection,
  } = useGlobalContext();

  // Hooks to get cart, wishlist, and compare counts
  const totalCart = useTotalProductCount(); // Cart count

  const totalWishlist = useSelector(
    (state: RootState) => state.wishlist?.wishlistProducts?.length || 0 // Safely access wishlist count
  );
  const totalCompare = useSelector(
    (state: RootState) => state.compare?.compareProducts?.length || 0 // Compare count from Redux, fallback to 0
  );

  // Access auth state
  const auth = useSelector((state: RootState) => state.auth);
  const { customer } = auth;

  const handleCompare = () => {
    router.push('/compare'); // Navigate to compare page when clicked
  };

  const handleLogout = () => {
    dispatch(logout());
    router.push('/login');
  };

  // Fetch the slogan data from the API
  useEffect(() => {
    const fetchSlogan = async () => {
      try {
        const response = await axios.get(
          'https://partspluseg.com/public/api/slogan'
        );
        const sloganData = response.data;

        if (sloganData && sloganData.length > 0) {
          setSlogan(sloganData[0].slogan); // Set the first slogan from the API
        }
        setLoading(false);
      } catch (err) {
        setError('Failed to load the slogan.');
        setLoading(false);
      }
    };

    fetchSlogan();
  }, []);

  return (
    <header className="header3">
      {/* Notification Banner */}
      <div className="header-note" style={{ display: open ? 'block' : 'none' }}>
        <p>{loading ? 'Loading...' : error ? error : slogan}</p>
        <span onClick={() => setOpen(!open)} className="note-close-btn">
          <i className="flaticon-cancel"></i>
        </span>
      </div>

      {/* Top Header Section */}
      <div className="header3-top d-none d-lg-block">
        <div className="container header-container">
          <div className="row align-items-center">
            <div className="col-lg-4">
              <SearchHeaderTwo />
            </div>
            <div className="col-lg-3">
              <div className="header-logo header3-logo">
                <Link href="/" className="logo-bl">
                  <Image src={logo} alt="logo-img" width={160} height={80} />
                </Link>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="action-list d-none d-md-flex action-list-header3">
                {/* User Profile */}
                {customer ? (
                  <div className="user-btn action-item">
                    <Link href="/profile">
                      <div className="user-icon">
                        <UserIcon />
                      </div>
                      <span className="action-btn-text">
                        {customer.first_name}
                      </span>
                    </Link>
                    {/* Logout Option */}
                    <button
                      onClick={handleLogout}
                      className="logout-button"
                    >
                      Logout
                    </button>
                  </div>
                ) : (
                  <div className="user-btn action-item">
                    <Link href="/profile">
                      <div className="user-icon">
                        <UserIcon />
                      </div>
                    </Link>
                    <Link href="/login" className="action-btn-text">
                      Sign in
                    </Link>
                  </div>
                )}

                {/* Cart Button */}
                <div
                  onClick={() => setSideCartOpen(!sideCartOpen)}
                  className="action-item action-item-cart"
                >
                  <button type="button" className="view-cart-button">
                    <CartIcon />
                    <span className="action-item-number">{totalCart}</span>
                  </button>
                  <button type="button" className="action-btn-text">
                    Cart
                  </button>
                </div>

                {/* Wishlist Button */}
                <div
                  onClick={() => setSideWishlistOpen(!sideWishlistOpen)}
                  className="action-item action-item-wishlist"
                >
                  <button type="button" className="view-wishlist-button">
                    <WishlistIcon />
                    <span className="action-item-number">{totalWishlist}</span>
                  </button>
                  <button type="button" className="action-btn-text">
                    Wishlist
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Mobile Sticky Header */}
      <div
        id="header-sticky"
        className={`header-main header-main3 ${
          scrollDirection === 'down' ? 'sticky' : ''
        }`}
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-12 col-lg-12">
              <div className="header-main-content-wrapper">
                <div className="header-logo header3-logo d-lg-none">
                  <Link href="/" className="logo-bl">
                    <Image src={logo} alt="logo-img" />
                  </Link>
                </div>
                <div className="main-menu main-menu3 d-none d-lg-block">
                  <nav id="mobile-menu">
                    <Menu />
                  </nav>
                </div>
                <div className="d-lg-none d-flex lh-1 ">
                  <div className="action-list action-list-header4">
                    {customer ? (
                      <div className="user-btn action-item profile-icon">
                        <Link href="/profile">
                          <div>
                            <UserIcon />
                            <span>{customer.first_name}</span>
                          </div>
                        </Link>
                      </div>
                    ) : (
                      <div className="user-btn action-item">
                        <Link href="/profile">
                          <div>
                            <UserIcon />
                          </div>
                        </Link>
                       
                      </div>
                    )}
                    {/* Mobile Cart Button */}
                    <div
                      onClick={() => setSideCartOpen(!sideCartOpen)}
                      className="action-item action-item-cart"
                    >
                      <button type="button" className="view-cart-button">
                        <CartIcon />
                        <span className="action-item-number">{totalCart}</span>
                      </button>
                    </div>

                    {/* Mobile Wishlist Button */}
                    <div
                      onClick={() => setSideWishlistOpen(!sideWishlistOpen)}
                      className="action-item action-item-wishlist"
                    >
                      <button type="button" className="view-wishlist-button">
                        <WishlistIcon />
                        <span className="action-item-number">
                          {totalWishlist}
                        </span>
                      </button>
                    </div>
                  </div>
                  <div onClick={toggleSideMenu} className="menu-bar ml-20">
                    <Link className="side-toggle" href="#">
                      <div className="bar-icon">
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> {/* End Mobile Sticky Header */}
    </header>
  );
};

export default HeaderTwo;
