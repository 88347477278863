// src/redux/slices/authSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Customer {
  id: number;
  first_name: string;
  email: string;
  // Add other customer fields as necessary
}

interface AuthState {
  token: string | null;
  customer: Customer | null;
}

const initialState: AuthState = {
  token: null,
  customer: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginSuccess: (
      state,
      action: PayloadAction<{ token: string; customer: Customer }>
    ) => {
      state.token = action.payload.token;
      state.customer = action.payload.customer;
    },
    logout: (state) => {
      state.token = null;
      state.customer = null;
    },
  },
});

export const { loginSuccess, logout } = authSlice.actions;

export default authSlice.reducer;
